
import Mock, { Random } from "mockjs";


// // /**
// //  * @mock login
// //  */
// // //登陆成功
// // const login_data_0 = {
// //     "errorCode": 200,
// //     "errorDesc": "登陆成功",
// //     "resultCode": 0,
// //     "resultData": {
// //         "expireTime":1666835819533,
// //         "accessToken": "thisisaccessToken",
// //         "refreshToken": "thisisrefreashToken",
// // 		"user": {
// //             "userId": "ohx0001",
// //             "username": "@cname",
// //             "loginTime":null,
// //             "expireTime":null,
// //             "others":"others"
// //         },
// //     }
// // }

// // //登陆失败时返回的data
// // const login_data_1 = {
// //     "errorCode": 0,
// //     "errorDesc": "",
// //     "resultCode": 1,
// //     "resultData": {}
// // }

// // Mock.mock(/\/hsyq\/user\/login/,'post',function(){
// //     return login_data_0
// // })


// // /**
// //  * @mock logout
// //  */
// // //用户登出
// // const logout_data = {
// //     "errorCode": 0,
// //     "errorDesc": "",
// //     "resultCode": 0,
// //     "resultData": null
// // }
// // // Mock.mock(/\/hsyq\/user\/logout/,'post',function(){
// // //     return logout_data
// // // })



// // /**
// //  * @mock refreshToken
// //  */
// // //刷新Token，注意refreshToken有可能没变
// // const refreshToken_data = {
// //     "errorCode": 0,
// //     "errorDesc": "",
// //     "resultCode": 0,
// //     "resultData": {
// //         "expireTime":1666835819533,
// //         "accessToken": "new accseeToken",
// //         "refreshToken": "new refreshToken",
// //     }
// // }
// // // Mock.mock(/\/hsyq\/service\/refresh/,'post',function(){
// // //     return refreshToken_data
// // // })


// // /**
// //  * @mock getUserInfo
// //  */
// // //刷新Token，注意refreshToken有可能没变

// // const phonePrefix = ['132', '135', '189']
// // const index = Math.floor(Math.random() * phonePrefix.length)
// // var phone = phonePrefix[index] + Mock.mock(/\d{8}/)

// // const getUserInfo_data = {
// //     "errorCode": 0,
// //     "errorDesc": "",
// //     "resultCode": 0,
// //     "resultData": null,
// //     "requestData":{
// //         "userId":"ohx12345",
// //         "username":Random.cname(),
// //         "phone":phone,
// //         "nickname":Random.string(),
// //         "com":"OHX",
// //         "department":"CEO",
// //         "email":Random.email(),
// //         "type":"",
// //         "others":"xxxx"
// //     }
// // }
// // // Mock.mock(/\/hsyq\/service\/getUserInfo/,'get',function(){
// // //     return getUserInfo_data
// // // })


// // /**
// //  * @mock changePassword
// //  */
// // //刷新Token，注意refreshToken有可能没变

// // const changePassword_data = {
// //     "errorCode": 0,
// //     "errorDesc": "",
// //     "resultCode": 0,
// //     "resultData": null
// // }
// // // Mock.mock(/\/hsyq\/service\/changePassword/,'post',function(){
// // //     return changePassword_data
// // // })


// // /**
// //  * @mock getBoatList
// //  */
// // var getBoatList_data={
// //     "errorCode": 0,
// //     "errorDesc": "",
// //     "resultCode": 0,
// //     "resultData": [
// //         {
// //             "CNname": "布拉希尔号",
// //             "an_name": "CARL BRASHEAR",
// //             "call_sign": "NBRS",
// //             "country": "USA",
// //             "hull_number": "T-AKE-7",
// //             "imo": "9345104",
// //             "labels": [
// //                 "Warship",
// //                 "ReplenishmentDryCargoVessel"
// //             ],
// //             "mmsi": "368709000",
// //             "name": "USNS CARL BRASHEAR"
// //         },
// //         {
// //             "CNname": "埃里克森号",
// //             "an_name": "JOHN ERICSSON",
// //             "call_sign": "NNJE",
// //             "country": "USA",
// //             "hull_number": "T-AO-194",
// //             "imo": "8511512",
// //             "labels": [
// //                 "Warship",
// //                 "ReplenishmentTanker"
// //             ],
// //             "mmsi": "367221000",
// //             "name": "USNS JOHN ERICSSON"
// //         }
// //     ]
// // }
// // // Mock.mock(/\/hsyq\/service\/getBoatList/,'get',function(){
// // //     console.log("mock on getBoatList") 
// // //     return getBoatList_data
// // // })


// // /**
// //  * @mock getPortList
// //  */
// //  var getPortList_data={
// //         "errorCode": 0,
// //         "errorDesc": "",
// //         "resultCode": 0,
// //         "resultData": [
// //             {
// //                 "name": "YOKOSUKA",
// //                 "CNname": "横须贺",
// //                 "longitude": "112",
// //                 "latitude": "20",
// //         "labels": [
// //                     "Area"
// //                 ],
// //                 "country": "9345104"
// //             },
// //         ]
// // }

// // Mock.mock(/\/hsyq\/service\/getPortList/,'get',function(){
// //     console.log("mock on getPortList")
// //     return getPortList_data
// // })


// // /**
// //  * @mock 3.2.8 getBoatListByPort
// //  */
// //  var getBoatListByPort_data={
// //     "errorCode": 0,
// //     "errorDesc": "",
// //     "resultCode": 0,
// //     "resultData": [
// //         {
// //             "CNname": "布拉希尔号",
// //             "an_name": "CARL BRASHEAR",
// //             "call_sign": "NBRS",
// //             "country": "USA",
// //             "hull_number": "T-AKE-7",
// //             "imo": "9345104",
// //             "labels": [
// //                 "Warship",
// //                 "ReplenishmentDryCargoVessel"
// //             ],
// //             "mmsi": "368709000",
// //             "name": "USNS CARL BRASHEAR"
// //         },
// //         {
// //             "CNname": "埃里克森号",
// //             "an_name": "JOHN ERICSSON",
// //             "call_sign": "NNJE",
// //             "country": "USA",
// //             "hull_number": "T-AO-194",
// //             "imo": "8511512",
// //             "labels": [
// //                 "Warship",
// //                 "ReplenishmentTanker"
// //             ],
// //             "mmsi": "367221000",
// //             "name": "USNS JOHN ERICSSON"
// //         }
// //     ]
// // }

// // Mock.mock(/\/hsyq\/service\/getBoatListByPort/,'get',function(){
// //     console.log("mock on getBoatListByPort")
// //     return getBoatListByPort_data
// // })

// // var getRelationshipList_data = {
// //     "errorCode": 0,
// //     "errorDesc": "",
// //     "resultCode": 0,
// //     "resultData": 
// //     {
// //     "linkList": [
// //         {
// //             "flag": "link",
// //             "from": "USS RAFAEL PERALTA",
// //             "text": "MOORED",
// //             "to": "YOKOSUKA",
// //             "type": "MOORED",
// //             "obj": {
// //                 "for": "NAN",
// //                 "from": {
// //                     "CNname": "拉斐尔佩拉尔塔号",
// //                     "an_name": "RAFAEL PERALTA",
// //                     "call_sign": "NSRP",
// //                     "country": "USA",
// //                     "hull_number": "DDG-115",
// //                     "imo": "4675633",
// //                     "labels": [
// //                         "Warship",
// //                         "Destroyer"
// //                     ],
// //                     "mmsi": "368926053",
// //                     "name": "USS RAFAEL PERALTA"
// //                 },
// //                 "time_end": 20220923,
// //                 "time_start": 20220923,
// //                 "to": {
// //                     "labels": [
// //                         "Area"
// //                     ],
// //                     "name": "FLEET ACTIVITIES YOKOSUKA"
// //                 },
// //                 "type": "MOORED"
// //             },
           
// //         }
// //     ],
// //     "nodeList": [
// //         {
// //             "flag": "node",
// //             "key": "YOKOSUKA",
// //             "text": "YOKOSUKA",
// //             "labels": [
// //                 "Area"
// //             ],
// //             "latlong": [
// //                 35.2813412,
// //                 139.6722005
// //             ],
// //             "obj": {
// //                 "labels": [
// //                     "Area"
// //                 ],
// //                 "name": "FLEET ACTIVITIES YOKOSUKA"
// //             },
            
// //         },
// //         {
// //             "flag": "node",
// //             "key": "USS ZUMWALT",
// //             "labels": [
// //                 "Warship",
// //                 "Destroyer"
// //             ],
// //             "latlong": [
// //                 41.23274461324424,
// //                 155.45673332184262
// //             ],
// //             "obj": {
// //                 "CNname": "朱姆沃尔特号",
// //                 "an_name": "ZUMWALT",
// //                 "call_sign": "NZUM",
// //                 "country": "USA",
// //                 "hull_number": "DDG-1000",
// //                 "imo": "4676766",
// //                 "labels": [
// //                     "Warship",
// //                     "Destroyer"
// //                 ],
// //                 "mmsi": "367698750",
// //                 "name": "USS ZUMWALT"
// //             },
// //             "text": "USS ZUMWALT",
// //         }
// //     ]
// // }
// // }


// // Mock.mock(/\/hsyq\/service\/getRelationshipList/,'get',function(){
// //     console.log("mock on getRelationshipList")
// //     return getRelationshipList_data
// // })

// // var getTrackList_data = {
// //     "errorCode": 0,
// //     "errorDesc": "",
// //     "resultCode": 0,
// //     "resultData": [
// //         {
// //             "arriveTime": 321019,
// //             "course": "2470",
// //             "destination": "WILMINGTON NC",
// //             "eventId": 0,
// //             "flag": 0,
// //             "ignore0": "0",
// //             "ignore1": "-1",
// //             "ignore2": "",
// //             "latitude": -5.294233,
// //             "longitude": 107.241814,
// //             "mmsi": "511",
// //             "speed": "200",
// //             "status": "0",
// //             "time": 1533874982
// //         },
// //         {
// //             "arriveTime": 910123,
// //             "course": "0742",
// //             "destination": "ZZPTest Port",
// //             "eventId": 0,
// //             "flag": 0,
// //             "ignore0": "0",
// //             "ignore1": "-1",
// //             "ignore2": "",
// //             "latitude": 5.294233,
// //             "longitude": -107.241814,
// //             "mmsi": "511",
// //             "speed": "200",
// //             "status": "0",
// //             "time": 1533874990
// //         }
// //     ]
// // }

// // // Mock.mock(/\/hsyq\/service\/getTrackList/,'get',function(){
// // //     console.log("mock on getTrackList")
// // //     return getTrackList_data
// // // })


var imageURL = ["http://www.afinance.cn/new/UploadFiles_2266/201209/20120921155406676.jpg",
                "https://pic2.zhimg.com/v2-2b3eb7eb8abe322b21b035b2a9d9e7fd_r.jpg",
                "https://pic3.zhimg.com/80/v2-0f3d24a628cfe5addd185222a2178b22_1440w.webp",
                "https://tse1-mm.cn.bing.net/th/id/OIP-C.WS1FoqBGPyznMLplZWPK1gHaHa?pid=ImgDet&rs=1",
                "https://ts1.cn.mm.bing.net/th/id/R-C.ddb092c6b40f29631a19e4efc9571230?rik=YTAEp37PA9MFcQ&riu=http%3a%2f%2fpic.ntimg.cn%2ffile%2f20170719%2f9885883_215628827000_2.jpg&ehk=PD70jFjo6Fs0ByZ5nc8X8HJ112FcfSZa54NA2GUoXps%3d&risl=&pid=ImgRaw&r=0",
                "https://d.ifengimg.com/q100/img1.ugc.ifeng.com/newugc/20210429/10/wemedia/336ee78cc6f4543abeddafadc773b91697638929_size1030_w3000_h2000.jpeg",
                "https://ts1.cn.mm.bing.net/th/id/R-C.5413effe73b3183e54fd5a341be9a709?rik=cm5ZEZPl4MYdQw&riu=http%3a%2f%2fupload.qianlong.com%2f2018%2f1126%2f1543218603220.jpg&ehk=FL8KfE5hOBDURUpuecLWGp4NztJ47xhD8Ts%2fWqIjMW0%3d&risl=&pid=ImgRaw&r=0",
                "https://ts1.cn.mm.bing.net/th/id/R-C.831c2d5f8f5dbfa80554153366f0d8ec?rik=nDYQN9QcPKeLWg&riu=http%3a%2f%2fi.guancha.cn%2fnews%2f2017%2f05%2f15%2f20170515181624799.jpg&ehk=vcD7HZwm85iJ6P6Ic1HkI%2bA48gFyvDFhW79mgQVSaLQ%3d&risl=&pid=ImgRaw&r=0",
                "https://x0.ifengimg.com/ucms/2021_27/F806BDB99906E2A2B436D79DD6AB81A48CE1F357_size81_w650_h366.jpg",
                "https://ts1.cn.mm.bing.net/th/id/R-C.723f06c3db7ba4b58f4bc477cbccaaff?rik=ke3e5mZT48%2f5aw&riu=http%3a%2f%2fwcm1.cnr.cn%2fpub%2fen_US%2fjs2014%2fycdj%2f20200612%2fW020200612562042985265.jpg&ehk=%2f4t%2fhI7ZM2vcT6ULQnvOn0DIxYpxRnxnwWuvjcXkBsc%3d&risl=&pid=ImgRaw&r=0&sres=1&sresct=1",
                "https://ts1.cn.mm.bing.net/th/id/R-C.cedd67fa165cc64bc1e077c3d61ced59?rik=DNgyxsWNof37ig&riu=http%3a%2f%2f5b0988e595225.cdn.sohucs.com%2fimages%2f20200327%2f2ce5235d3b924b30a74849e2f25abddc.png&ehk=GGr3vIeAhezkjYsY3uJ%2bSF%2fzoOGI75NmDlQ7tFGlcBY%3d&risl=&pid=ImgRaw&r=0",
                "https://himg2.huanqiucdn.cn/attachment2010/2015/0323/23/11/20150323111138885.jpg",
                "https://p1.ssl.qhmsg.com/t0198ca27c07e1a8f6e.jpg",
                "https://ts1.cn.mm.bing.net/th/id/R-C.bccfb1e319e394ec4521b6e5d1d34a23?rik=gUZH8VEPh03RJA&riu=http%3a%2f%2fn.sinaimg.cn%2fspider2020414%2f211%2fw2048h1363%2f20200414%2fc57f-isehnnk2442177.jpg&ehk=C0tBB%2blPoHGDG2lBflehhTprmcR3e%2fXqV7fZQpmPx04%3d&risl=&pid=ImgRaw&r=0",
                "https://ts1.cn.mm.bing.net/th/id/R-C.f0c189a2ce331e2e038121b0b5a7893a?rik=j1lilryItSE1mQ&riu=http%3a%2f%2fup.deskcity.org%2fpic%2f45%2f48%2fc3%2f4548c3d8f7b6b9f1df15784563850599.jpg&ehk=0vqr0v5%2f%2bWL0IC9htkpU801c2Khsl1YqNgF%2fohQpp3M%3d&risl=&pid=ImgRaw&r=0",
                "https://p1.ssl.qhimg.com/t0107ffea71ae9bb0fb.jpg",
                "https://ts1.cn.mm.bing.net/th/id/R-C.02579ee5ef20d18581319447750a96fd?rik=mPzKPpO2SV6MvA&riu=http%3a%2f%2fn.sinaimg.cn%2fsinakd10114%2f20%2fw2000h1220%2f20200904%2f0f1e-iytwsaz8615595.jpg&ehk=zr3PRBvcI8ilX9A62hEYG0GLUyQsRpiSP5oD%2bv2Hxxw%3d&risl=&pid=ImgRaw&r=0",
                "https://pic4.zhimg.com/v2-d61257d43d281af1ee0039dfbeff6aa7_r.jpg",
]


function getrecords(){
    let images = []
        for(let i=0;i<4;i++){
            try{
                // 分页查询，size固定为5，每次取出currentLength张图，
                images.push(imageURL[i])   
            }catch(e){
                break
            }  
        } 
        return images
}

var getBoatURL_data = {
    "errorCode": 0,
    "errorDesc": "",
    "resultCode": 0,
    "resultData": {
        "total": imageURL.length,               // 总的图片数量
        "pages": parseInt(imageURL.length/5),   // 后端按照提供的size，计算出的总页数
        "size": 5,                              // 本次要取的数量，由前端发出
        "current": 1,                           // 当前页码
        "currentLength": 4,                     // 后端返回实际取到的图片数
        "records": getrecords()
    }
}

// Mock.mock(/\/hsyq\/service\/getTestBoatURL/,'get',function(){
//     console.log("mock on getBoatURL")
//     return getBoatURL_data
// })


// var getMMSIByType_data = {
//     "errorCode": 0,
//     "errorDesc": "",
//     "resultCode": 0,
//     "resultData": [
//         {
//             "type0": [
//                 "366779000",
//                 "369839751"
//             ]
//         },
//         {
//             " type1": [
//                 "366779000",
//                 "369839751"
//             ]
//         },
//         {
//             " type2": [
//                 "366779000",
//                 "369839751"
//             ]
//         },
//         {
//             " type3": [
//                 "366779000",
//                 "369839751"
//             ]
//         },
//         {
//             " type4": [
//                 "366779000",
//                 "369839751"
//             ]
//         },
//         {
//             " type5": [
//                 "366779000",
//                 "369839751"
//             ]
//         },
//         {
//             " type6": [
//                 "366779000",
//                 "369839751"
//             ]
//         },
//         {
//             " type7": [
//                 "366779000",
//                 "369839751"
//             ]
//         },
//         {
//             " type8": [],
//             " type9": [
//                 "366779000",
//                 "369839751"
//             ]
//         },
//         {
//             " type10": [
//                 "366779000",
//                 "369839751"
//             ]
//         },
//         {
//             " type11": [
//                 "366779000",
//                 "369839751"
//             ]
//         }
//     ]
// }

// // Mock.mock(/\/hsyq\/service\/getMMSIByType/,'get',function(){
// //     console.log("mock on getMMSIByType")
// //     return getMMSIByType_data
// // })

// var checkEmail_data = {
//     "errorCode": 0,
//     "errorDesc": "",
//     "resultCode": 0,
//     "resultData": "this is checkEmail_resultData"
// }

// Mock.mock(/\/hsyq\/user\/checkEmail/,'post',function(){
//     console.log("mock on checkEmail")
//     return checkEmail_data
// })

// var sendEmailCode_data = {
//     "errorCode": 0,
//     "errorDesc": "",
//     "resultCode": 0,
//     "resultData": "this is sendEmailCode_resultData"
// }

// Mock.mock(/\/hsyq\/user\/endEmailCode/,'get',function(){
//     console.log("mock on endEmailCode")
//     return sendEmailCode_data
// })


// var Register_data = {
//     "errorCode": 0,
//     "errorDesc": "",
//     "resultCode": 0,
//     "resultData": "this is Register_resultData"
// }

// Mock.mock(/\/hsyq\/user\/Register/,'post',function(){
//     console.log("mock on Register")
//     return Register_data
// })