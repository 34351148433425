import axios from "axios";
// import qs from "qs";
import store from "@/store";

let Base64 = require('js-base64').Base64

let http1 = axios.create({
  baseURL: "/api", //线上环境
  timeout: 1000 * 1000
});

http1.interceptors.request.use(
  function(config) {
    // if (config.method.toLowerCase() == "post") {
    //   config.data = qs.stringify(config.data);
    // }
    if (!window.isTest) {
      // config.headers = {
      //   "Content-Type": "application/json;charset=utf-8",
      //   Authorization: store.getters["getToken"],
      //   channelId: "JHK20200616ZHJJHXJ0100"
      // };
    }
    console.log(config);
    console.log("请求的url是" + config.baseURL + config.url);
    console.log("请求的参数是" + JSON.stringify(config));

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

http1.interceptors.response.use(
  function(response) {
    console.log(response);
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);


function getHttpInstanse(flag) {
  if (flag === 1) {
    return getAisData;
  } 
}

function getAisData(cmd, paramJson){
  return http1({
    url: "/api",
    method: "get",
    params: {
      cmd,
      param: Base64.encode(JSON.stringify(paramJson)),
      time: parseInt(Date.now() / 1000),
      api_key: 'yAjFdLc$Mb76@9rC'
    },
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      // Authorization: this.jsonStr.token,
      channelId: "JHK20210531ZDSYKX00113"
    }
  })
}


export default getHttpInstanse;

